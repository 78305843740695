import { Injectable } from '@angular/core';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import { TableConversion } from '../../../../shared/conversion/table.conversion';
import { UserConversion } from '../../../organization/users/conversion/user.conversion';
import { AuditableItemListDTO } from '../../audit-universe/dto/auditable-item.dto';
import { AuditUniverseAuditableItemConversion } from '../../audit-universe/conversion/audit-universe-auditable-item.conversion';

@Injectable({
  providedIn: 'root',
})
export class AuditPlanAuditableItemConversion {
  private lang: string;

  constructor(
    private currentUserPreferenceStore: CurrentUserPreferenceStore,
    private auditUniverseAuditableItemConversion: AuditUniverseAuditableItemConversion,
    private tableConv: TableConversion,
    private userConv: UserConversion
  ) {
    this.lang = currentUserPreferenceStore.languageKey;
  }

  resToList(response: any[]): AuditableItemListDTO[] {
    if (!response) {
      return [];
    }

    return response.map((item) => {
      const { title, type } =
        this.auditUniverseAuditableItemConversion.getAuditableItemTypeTitle(
          item
        );

      return {
        id: item.auditable_item_id,
        title: title,
        type: type,
        auditable_item_category_title:
          item[`audit_category_title_${this.lang}`],
        comment: item.auditable_item_comment,
        auditor_risk_rating: this.tableConv.resToColordItem(
          item,
          'auditor_risk_rating'
        ),
        responsible_unit: item[`responsible_unit_title_${this.lang}`] || '',
        created_at: item.created_at,
        updated_at: item.updated_at || null,
        responsible_user: this.userConv.resToUserMinimalDTO(
          item,
          'responsible_user'
        ),
        created_by: this.userConv.resToUserMinimalDTO(item, 'created_user'),
        updated_by: this.userConv.resToUserMinimalDTO(item, 'updated_user'),
      };
    });
  }
}
